@import '../../styles/colors', '../../styles/typography.scss';

.banner {
    height: 444px;
    img {
        border-radius: 0px 24px 24px 0px;
        display: flex;
        flex: 1;
        height: 100%;
        object-fit: cover;
    }
    .label-container {
        width: 490px;
        height: 100%;
        background-color: $brand-color;
        border-radius: 24px 0px 0px 24px;
        padding-left: 80px;
        position: relative;
        &::after {
            content: '';
            position: absolute;
            right: 0px;
            transform: translateX(calc(100% - 2px));
            height: 444px;
            width: 412px;
            background: linear-gradient(90deg, #A02A23 0%, rgba(160, 42, 35, 0) 100%);
        }
    }
    .banner-image-container {
        flex: 1;
        height: 100%;
    }
}

@media(max-width: 991px) {
    .banner {
        flex-direction: column-reverse;
        height: auto;
        .label-container {
            border-radius: 0px 0px 24px 24px;
            width: 100%;
            padding: 32px 16px 40px 16px;
            align-items: center;
            text-align: center;
            &::after {
                transform: translateX(0px);
                width: 100%;
                background: linear-gradient(0deg, #A02A23 0%, rgba(160, 42, 35, 0) 100%);
                top: 0px;
                transform: translateY(calc(-100% + 2px));
                height: 220px;
            }
        }
        .banner-image-container {
            img {
                border-radius: 24px 24px 0px 0px;
            }
        }
    }
}

@media(max-width: 768px) {
    .banner {
        .label-container {
            p {
                font-size: 28px;
            }
        }
    }
}