$brand-color: #A02A23;
$brand-color-2: #28C76F;

$white: #fff;
$black: #000;

$text-color: #5E5873;
$text-color-2: #6E6B7B;
$text-color-3: #F8F8F8;

$gray-medium: #82868B;